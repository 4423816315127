import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import logoSEO from '../images/logoPNG.png';
import AuthorizeErrorText from '../components/authorize/authorize-error-text/authorizeErrorText';

const NotFoundPage = () => (
	<Layout>
		<SEO
			description="Wyborek | Głosuj szybko i łatwo!"
			image={logoSEO}
			title="Nie znaleziono głosowania"
		/>
		<AuthorizeErrorText
			infoText="Nie znaleziono głosowania"
		/>
	</Layout>
);

export default NotFoundPage;
